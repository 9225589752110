$(document).ready(function(){
  $(".datepicker").each(function(){
    var input;
    if($(this).is("input")){
      input = $(this);
    }else {
      input = $(this).find('input');
    }
    
    input.each(function(){
      if ($(this).val().length > 0){
        var d;
        if ($(this).hasClass("just-date")){
          date = $(this).val().split("/")
          d = new Date(date[2], date[1]-1, date[0]);
        } else {
          d = new Date(parseInt($(this).val(), 10));
        }

        var day = ('0' + d.getDate()).slice(-2);
        var month = ('0' + (d.getMonth() + 1)).slice(-2); // getMonth() is zero-indexed
        var year = d.getFullYear();

        this.value = `${day}/${month}/${year}`
      }
    });
    
    $(this).datepicker({
      useCurrent: false,
      locale: $('html').attr('lang'),
      format: 'dd/mm/yyyy',
      autoclose: true
    });
  });
});