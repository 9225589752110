$(document).ready(function() {
  $("#downloadDocuments").click(function(e) {
    e.preventDefault()
    var ids = []
    $(".selectSingle:checked").each(function() {
      ids.push($(this).data('id'))
    });
    
    window.open("/download_documents?documents_ids="+ids.join(','),"_self");
  });

  $('[data-toggle="tooltip"]').tooltip();

  $(".action-icon").click(function(e) {
    e.preventDefault()
    var btn = $(this);

    $.ajax('/import_document', {
      type: 'DELETE',
      data: { file: btn.data('file') },
      success: function (data, status, xhr) {
        btn.closest(".item-upload").remove();
      },
      error: function (jqXhr, textStatus, errorMessage) {
        alert("Error trying to delete file");
      }
    });
  });

});