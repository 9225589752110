$(document).ready(function(){
  $(".filter-icon").click(function(e) {
    e.preventDefault();
    $(".filter-container").show()
  });
  
  $("#filterCancel").click(function(e) {
    e.preventDefault();
    $(".filter-container").hide()
  });
});
