$(document).ready(function(){
  if (!$('.selectized').hasClass("select2-hidden-accessible")) {
    $('.selectized').select2();
    $(".documents-user-select2").select2({
      placeholder: "Place name here...",
      allowClear: true
    });
  }
  $(".two-step-form, .back-arrow").click(function(e){
    e.preventDefault();
    $(".first-step, .back-arrow, .second-step").toggleClass("d-none");
    $(".step-ball").toggleClass("active");
  });
});
