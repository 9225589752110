$(document).ready(function(){
  $('#small-target-collapsible').on('show.bs.collapse', function () {
    var $text = $('#small-target-title');
    var $arrow = $('#small-target-arrow');
    var $arrow_rotated = $('#small-target-arrow-rotated');
    
    $text.text($text.data('hide'));
    $arrow.hide();
    $arrow_rotated.show();
  });

  $('#small-target-collapsible').on('hide.bs.collapse', function () {
    var $text = $('#small-target-title');
    var $arrow = $('#small-target-arrow');
    var $arrow_rotated = $('#small-target-arrow-rotated');
    
    $text.text($text.data('show'));
    $arrow.show();
    $arrow_rotated.hide();
  });
});  