$(document).ready(function() {
  $("#selectAll").change(function() {
    if (this.checked) {
      $(".selectSingle").each(function() {
        this.checked=true;
      });
    } else {
      $(".selectSingle").each(function() {
        this.checked=false;
      });
    }
    select_counter();
  });

  $(".selectSingle").click(function () {
    if ($(this).is(":checked")) {
      var isAllChecked = 0;
      
      $(".selectSingle").each(function() {
        if (!this.checked)
        isAllChecked = 1;
      });
      
      if (isAllChecked == 0) {
        $("#selectAll").prop("checked", true);
      }     
    }
    else {
      $("#selectAll").prop("checked", false);
    }
    select_counter();
  });

  function select_counter() {
    $("#selected-counter").html($(".selectSingle:checked").parents(".item-upload.row").not(".is_annex").length);
  }
});