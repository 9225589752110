$(document).ready(function(){
  $('#notification_documentable_type').change(function() {
    const endpointMappings = {
      'Document': 'documents',
      'PinnedDoc': 'pinned_docs'
    };
    var type = $(this).val();
    var idSelect = $('#notification_documentable_id');

    idSelect.find('option').remove();
    idSelect.prop('disabled', true);

    if (type) {
      $.get('/' + endpointMappings[type] + '.json', function(data) {
        data.forEach(function(item) {
          idSelect.append($('<option>').val(item.id).text(item.name));
        });
        idSelect.prop('disabled', false);
      });
    }
  });

  $('#notification_notify_everyone').change(function() {
    var users_select = $('#notification_user_ids');

    if ($(this).is(':checked')) {
      users_select.val([]).trigger('change').prop('disabled', true);
    } else {
      users_select.prop('disabled', false);
    }
  });
});