$(document).ready(function(){
  var cropper_img_profile;

  $('.imgInp').change(function() {
    if (this.files && this.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.cropper-container').remove();
        recreate_element('img_cropper')
        let uploaded_img = document.getElementById('img_cropper');
        uploaded_img.onload = $('#modal_profile_img').modal({ backdrop: 'static' }).modal('show');
        uploaded_img.src = e.target.result;
      }
      reader.readAsDataURL(this.files[0]);
    }
  });

  $('#modal_profile_img').on('shown.bs.modal', function () {
    let img_to_crop = $('#img_cropper')[0];
    cropper_img_profile = new Cropper(img_to_crop, {
      aspectRatio: 1 / 1,
      viewMode: 1,
      background: false,
      modal: false,
      responsive: true,
      autoCropArea: 1
    });
  });

  $('#modal_profile_img').find('.btn-primary').on('click', function() {
    let img_cropper = cropper_img_profile.getCroppedCanvas().toDataURL();
    let img_uploaded = document.getElementById('imageUploaded');
    img_uploaded.src = img_cropper;
    let input_img_profile = document.getElementById('img_profile');
    input_img_profile.value = img_cropper;
    $('#modal_profile_img').modal('hide');
  });
});

function recreate_element(id) {
  let original_elem = document.getElementById(id);
  if (!original_elem) return;
  let parent_node = original_elem.parentNode;
  let tag_name = original_elem.tagName.toLowerCase();
  parent_node.removeChild(original_elem);
  let new_elem = document.createElement(tag_name);
  new_elem.id = id;
  new_elem.style.maxWidth = '100%';
  parent_node.appendChild(new_elem);
}
