// Here you can require the custom modules for this application like the admin one,
// for each module you need to add a folder in 'app/javascript' containing at least
// an 'index.js' file as the entrypoint.
require('filter')
require('navbar')
require('image-upload')
require('datepicker')
require('select2-utils')
require('checkbox-selectAll')
require('documents-functions')
require('transactions')
require('card-image')
require('salto')
require('notifications')

require("trix")
require("@rails/actiontext")
