$(document).ready(function() {
  $('#user_has_salto_key').change(function() {
    var selectedValue = $(this).val();

    if (selectedValue === 'true') {
      var userId = $('#user_id').val();

      $.ajax({
        url: '/profiles/' + userId + '/check_salto_activation',
        type: 'GET',
        dataType: 'script'
      });
    }
  });
});